.headerContainer{
	position: sticky;
	top: 0;
	z-index: 999;
}

.header1{
	background-color: transparent;
	width: 100%;
	transition: all .15s ease-in-out;
}

.header1_2{
	background-color: transparent;
	width: 100%;
	transition: all .15s ease-in-out;
	box-shadow: 0 0 10px 1px rgba(black, .1);
	border-bottom: solid 1px $gray5;
	display: block;
	position: relative;
	
	& p{
		color: #717580 !important;
	}

	& .menu {
		& button {
			color: $colorPrimary!important;
			border: solid 1px lighten($gray4, 10) !important;
			& i {
				color: $colorSecondary !important;
			}

			&:hover{
				background-color: $gray5 !important;
			}
		}
	}
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .15);
	z-index: 999;
	position: relative;
}

.header2{
	background-color: white;
	width: 100%;
	z-index: 99999999;
}

#sub_menu_vendas{
	display: none;
	min-width: 230px;
	max-height: 300px;
	left: 0%;
	top: 2.4rem;
	overflow: auto;
	position: absolute;
	background-color: white;
	box-shadow: 0px 0px 10px rgba(black, .1);
	border-radius: .5rem;
	z-index: 3;

	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		padding: .5rem 0;

		& p{
			font-size: .85rem !important;
			font-weight: 500;
			background-color: none;
			padding: 0.5rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s;
			color: $colorTerciary;

			&:hover{
				background-color: $gray5;
				padding-left: 2rem;
			}
		}

		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}

	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	padding: 0;

	& .superior {
		display: flex;
		width: 100%;
		gap: 1rem;
		flex-direction: row;
		align-items: center;
		z-index: 3;
		
		& > div:nth-child(3){
			flex: 1;
		}

		/* GRUPO HEADER */
		& .logo_container {
			display: inline-block;
			margin-left: 0;
			@include border-box;
			vertical-align: middle;
			width: fit-content;
			margin-right: 0;
			margin-block: 5px;
			
			& .img{
				display: block;
				
				& img {
					display: block;
					user-select: none;
					max-height: 65px;
					border-radius: 5px;
				}
			}
		}

		& .session_menu{
			width: fit-content;
			vertical-align: middle;
			display: flex;
			justify-content: flex-start;
			margin-left: 0;
			
			& ul {
				display: flex;
				align-items: center;
				justify-content: start;
				margin: 0%;
				padding: 0%;
				list-style: none;
				gap: 1rem;

				 .creci_principal{
					color: #fff;
					font-size: .85rem;
				 }

				 .itens_header2{
					position: relative;
					display: block;
					padding: 0;
					
					&:hover{
						#sub_menu_vendas{
							display: initial;
						}
					}

					& a{
						justify-content: space-between;
						color: white;
						font-weight: 500;
						font-size: .85rem;
						transition: all .2s;
					}

					& .itemsession{
						position: relative;
						display: inline-block;
						vertical-align: middle;
						width: 100%;

						 .title_is{
							width: 100%;
							display: block;

							 .itemsession_title{
								vertical-align: middle;
								display: inline-block;
								width: 100%;
								font-size: .85rem;
								text-align: center;
								text-transform: initial;
								font-weight: 500;
								padding: 0;
								color: #fff;
								@include border-box;
								transition: all .15s ease-in-out;
								& i{
									vertical-align: middle;
									display: inline-block;
									font-size: .65rem;
									color:  white;
									font-weight: 300;
								}
							}
						}
	
					}
				}

				& .busca_inteligente{
					display: none;
					width: 100%;
					border: solid 1px $gray4;
					border-radius: 2rem;
					position: relative;
				
					& input{
						width: 100%;
						display: block;
						background-color: transparent;
						padding: .5rem 2rem .5rem 1rem;
						border: none;
						color: $gray1;
						&::placeholder{
							color: $gray3 !important;
						}
					}
				
					& .btn_buscar{
						background-color: $colorPrimary;
						width: fit-content;
						border: none;
						text-align: center;
						user-select: none;
						cursor: pointer;
						border-radius: 50%;
						position: absolute;
						top: 50%;
						right: 0.75%;
						transform: translate(-0.75%, -50%);
						padding: .8rem;
						transition: all .2s ease-in-out;

						& i{
							color: white;
							font-size: .8rem !important;
							font-weight: 400;
							position: absolute;
							transform: translate(-50%, -50%);
							padding-top: 3px;
						}

						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}

			}
		}

		& .sessions {
			display: flex;
			width: fit-content;
			justify-content: flex-end;
			margin-right: 0;

			& ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 0%;
				padding: 0%;
				list-style: none;

				& .itens_header{
					display: inline-block;
					padding: 0 20px;
					justify-content: space-between;
					
					& a{
						& p{
							color: white;
							font-weight: 500;
							font-size: .85rem;
							margin: 0;
							padding: 0;
							transition: all .15s ease-in-out;
						}
					}
				}
			}
		}

		& .menu {
			text-align: right;
			display: inline-block;
			width: fit-content;
			vertical-align: middle;
			margin: 0;

			& button{
				background-color: transparent;
				border: solid 1px white;
				border-radius: 2rem;
				color: white;
				padding: .5rem 1rem;
				cursor: pointer;
				font-size: .9rem;
				font-weight: 600;
				transition: all .2s ease-in-out;

				&:hover{
					background-color: white;
					color: $colorPrimary;

					& i {
						color: $colorSecondary;
					}
				}
			}
		}
	}
}

.headerindex {
	height: 55vh;
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: calc(55vh + 7rem);
		width: 100%;

		& .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: calc(55vh + 7rem);
			background: linear-gradient(to bottom, rgba(black, .6), rgba(black, .2));
			z-index: 2;
		}

		& .bg-headerpc{
			display: block;//era block
			height: calc(55vh + 7rem);
			width: 100%;
			z-index: 2;
			background-image: url('../../jnh/header/bg_header5.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}

	}
}

.header_mob{
	width: 100%;
	display: block;

	& .header-mob{
		display: flex;
		justify-content: space-between;
		align-items: center;

		& .box_logo_mobile{
			margin: 0;
			padding: 16px;
			.log{
				img{
					height: 52px;
				}
			}

		}
	
		& .box_btn_menu_mobile{
			vertical-align: middle;
			text-align: right;
			margin: 0;
	
			& .btn-men{
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				& button{
					color: $colorPrimary;
					background-color: transparent;
					border: none;
					border-radius: 2rem;
					padding: 0 1rem;
					cursor: pointer;
					font-weight: 600;
					transition: all .2s ease-in-out;
					& i{
						font-size: 2rem;
					}
				}
			}
		}
	}
}

.btn_menu_header{
	background-color: transparent !important;
	color: $colorPrimary !important;
	border: solid 1px lighten($gray4, 10) !important;

	& i {
		color: $colorSecondary;
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1201px) and (max-width: 1366px){

	.headerindex {
		height: calc(100vh - 5.7rem);

		& .container-bg-headerpc{
			height: 100vh;
	
			& .overlay_bg_home{
				height: 100vh;
			}
	
			& .bg-headerpc{
				height: 100vh;
				background-position: center bottom 25%;
			}
		}
	}

	.headerpc {
		padding: 0 2rem;
		& .superior {
			gap: 0;

			& .logo {
				padding-right: 1rem;
				& .img{
					width: calc(218px / 1);
					height: calc(82px / 1);
					
					& img {
						width: calc(218px / 1);
						height: calc(65ṕx / 1);
					}
				}
			}

			& .session_menu{
				& ul {
					& .busca_inteligente{
						& .btn_buscar{
							right: 1.5%;
							transform: translate(5%, -48%);
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1200px){
	.headerindex {
		height: calc(100vh - 5.7rem);

		& .container-bg-headerpc{
			height: 100vh;
	
			& .overlay_bg_home{
				height: 100vh;
			}
	
			& .bg-headerpc{
				height: 100vh;
				background-position: center bottom 25%;
			}
		}
	}

	.headerpc {
		padding: 0 1rem;
		& .superior {
			gap: 0;

			& .logo {
				padding-right: 1rem;
				& .img{
					width: calc(218px / 1);
					height: calc(82px / 1);
					
					& img {
						width: calc(218px / 1);
						height: calc(82px / 1);
					}
				}
			}

			& .session_menu{
				margin: 0;

				& ul {
					gap: 2rem;
					& .busca_inteligente{
						& .btn_buscar{
							right: 1.5%;
							transform: translate(5%, -48%);
						}
					}
				}
			}

			& .sessions {
				margin: 0;

				& ul {
					gap: 2rem;
					& .itens_header {
						margin-right: 1rem;
						padding: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: calc(100vh - 4.9rem);

		& .container-bg-headerpc{
			height: 100%;
	
			& .overlay_bg_home{
				height: 100%;
			}
	
			& .bg-headerpc{
				height: 100%;
				background-image: url('/jnh/header/bg_header5.jpg'); 
			}
		}
	}


	.headerpc{
		& .logo{
			padding: 0.3rem 0 0 0 !important;
		}
	}
	
	.container-header-pc{
		display: block;
		width: 100%;
		position: initial;
		background-color: initial !important;
		top: initial;
		left: initial;
		transform: initial;
		z-index: 10;
		max-width: 65rem;
	}
	
}

.dark_content{
	background: #fff;
	box-shadow: 0 0 20px 1px rgba(black, .15);
	
	.itemsession_title{
		color: #717580 !important;
	}

	.itens_header{
		p{
			color: #717580 !important;
		}
	}

	.ics.ic-chevron-down {
		color: $colorSecondary!important;
	}
}

.scrolled{
	.busca_inteligente{
		display: block !important;
	}	
}