.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: -9px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 12px !important;
		padding: .7rem 0;
		color: $colorTerciary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: white;
		color: $gray1 !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px $gray4;
		border-radius: 2rem;
		padding:  0 1rem;
		text-align: left;
	}
	& .component {
		border: 1px solid $gray4 !important;
		z-index: 10;
		border-radius: $borderRadius;

		& .items{
			overflow: auto;
			& .group{
				color: $gray1 !important;
				font-size: .9rem;
			}
		}


		& .botoes {
			background-color: white;
			border-top: solid 1px $gray5;
			padding: .5rem;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
			& .undo{
				background-color: transparent;
				color: $gray2;
				border: solid 1px transparent;
				font-weight: 600;
				padding: 0.5rem 0;
				border-radius: $borderRadius;
				cursor: pointer;
				text-transform: uppercase;
				font-size: 0.65rem;
				transition: all .15s ease-in-out;

				&:hover{
					background-color: $gray5;
				}
			}

			& .ok{
				background-color: $colorPrimary;
				color: white !important;
				border: solid 1px $colorPrimary;
				font-weight: 600;
				padding: 0.5rem 0;
				border-radius: $borderRadius;
				cursor: pointer;
				text-transform: uppercase;
				font-size: 0.65rem;
				transition: all .15s ease-in-out;

				&:hover{
					background-color: lighten($colorPrimary, 5);
				}
			}
		}
	}

}
