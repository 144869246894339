.imoveis_semelhantes{
	display: block;
	width: 100%;
	background-color: white;
	border: solid 1px $gray5;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);
	padding: 1rem;
	border-radius: $borderRadius;

	& h2{
		font-size: 1.1rem;
		text-align: left;
		color: $gray1;
		font-weight: 600;
		margin: 0;
	}

	& .container_miniaturas_semelhantes{
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		& .miniatura_interna{
			background-color: white;
			width: 100%;
			display: block;
			padding: .7rem;
			border: solid 1px lighten($gray4, 10);
			position: relative;
			border-radius: $borderRadius;

			& a{
				display: block;
				width: 100%;
		
				& .bloco_img{
					width: 40%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
		
					& .miniatura_imovel_img{
						display: block;
						width: 100%;
						height: 7rem;
						overflow: hidden;
						background-color: $gray5;
						position: relative;
						user-select: none;
						border-radius: $borderRadius;
				
						& img{
							display: block;
							width: 100%;
							height: inherit;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							border-radius: $borderRadius;
							object-fit: cover;
						}
					}
				}
			
				& .bloco_informacoes{
					width: 60%;
					display: inline-block;
					vertical-align: top;
					margin: 0;
					padding-left: 1rem;

					& .miniatura_informacoes{
						display: block;
						width: 100%;

						& .miniatura_titulo{
							display: block;
							width: 100%;
					
							& p{
								font-size: .9rem;
								font-weight: 500;
								color: $gray1;
								margin: 0;
							}
						}
							
						& .codigo_imovel{
							width: 100%;
							display: block;

							& p{
								font-size: .8rem;
								font-weight: 400;
								color: $gray1;
							}
																
							& .miniatura_acao{
								font-size: .8rem;
								font-weight: 400;
								margin: 0;
								color: $gray2;
								background-color: transparent;
							}
						}
		
						& .miniatura_endereco{
							display: block;
							width: 100%;
							& p{
								font-size: .8rem;
								font-weight: 400;
								color: $gray2;
								margin: 0;

								& i{
									font-size: .7rem;
									font-weight: 600;
									color: $colorTerciary;
								}
							}
						}

						& .miniatura_preco{
							display: block;
							width: 100%;

							& p{
								width: 100%;
								text-align: left;
								font-size: 1rem;
								font-weight: 500;
								color: $gray1;
								line-height: none;
								margin: 0;
							}

							& .tt{
								font-size: .75rem;
								color: $gray2;
								font-weight: 400;
								margin: .5rem 0 0 0;
							}
						}
					}
				}
				
				& .miniatura-imovel-btns-iconsX{
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-around;
					height: 4rem;
					width: 100%;
					background-color: $gray5;
					border-radius: $borderRadius;
		
					& ul{
						& li{
							font-size: .85rem;
							color: $gray2;
							font-weight: 400;
		
							& i{
								color: $colorPrimary;
								font-weight: 400;
								font-size: 1rem;
							}
						}
					}
				}
			}
			
		}

	}

	& .btn_vermais_interna{
		display: block;
		width: fit-content;
		margin: 0;
		& a{
			& p{
				font-size: .85rem;
				font-weight: 600;
				color: $gray2;
				background-color: white;
				border-radius: 2rem;
				border: solid 1px lighten($gray4, 10);
				padding: .4rem 1rem;
				text-align: center;
				transition: all .15s ease-in-out;
				margin: 0;

				& i {
					color: $colorTerciary;
				}

				&:hover{
					border-color: $colorTerciary;
				}
			}
		}
	}
}


@media (min-width: 1024px) and (max-width: 1366px){
	.imoveis_semelhantes{
		& .container_miniaturas_semelhantes{
			& .miniatura_interna{
				& a{
					& .bloco_informacoes{
						& .miniatura_informacoes{
							& .miniatura_titulo{
								& p{
									font-size: .8rem;
								}
							}
									
							& .miniatura_endereco{
								& p{
									font-size: .75rem;
									margin: .2rem 0 0 0;
									& i{
										font-size: .7rem;
									}
								}
							}
								
							& .codigo_imovel{
								& p{
									font-size: .75rem;
								}
																	
								& .miniatura_acao{
									font-size: .75rem;
									margin: .2rem 0 0 0;
								}
							}
	
							& .miniatura_preco{
								& p{
									margin: .2rem 0 0 0;
								}
							}
						}
					}
				}
			}
		}
	}
	
}

@media (max-width: 1023px){
	.imoveis_semelhantes{
		display: block;
		width: 100%;
		background-color: white;
		border: solid 1px $gray5;
		box-shadow: 0 0 10px 0 rgba($gray4, .5);
		padding: 1rem;
		border-radius: .2rem;

		& .container_miniaturas_semelhantes{
	
			& .miniatura_interna{
				border: solid 1px lighten($gray4, 10);
		
				& a{
					& .bloco_img{
						width: 100%;
						display: block;
						margin: 0;
			
						& .miniatura_imovel_img{
							height: 13rem;
						}
					}
				
					& .bloco_informacoes{
						width: 100%;
						margin: 1rem 0 0 0;
						padding-left: 0;
						padding: 0 .5rem;
						
						& .miniatura_informacoes{
							& .miniatura_titulo{
								& p{
									font-size: 1rem;
								}
							}
									
							& .miniatura_endereco{
								& p{
									font-size: .9rem;
									margin: .5rem 0;
								}
							}
								
							& .codigo_imovel{
								& .miniatura_acao{
									font-size: .9rem;
								}
							}
			
							& .miniatura_preco{
								display: block;
								width: 100%;
																
								& .tt{
									font-size: .9rem;
									color: $gray2;
									font-weight: 400;
									margin: 0;
								}

								& p{
									width: 100%;
									text-align: left;
									font-size: 1.2rem;
									font-weight: 600;
									color: $colorTerciary;
									line-height: none;
									margin: 0;
								}
							}
						}
					}

					& .miniatura-imovel-btns-iconsX{
						height: 4.5rem;
						gap: .5rem;
					}
				}
			}
		}
	}
}