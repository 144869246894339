.imovel-ver-titulo {

	& .valor {
		text-align: right;
	}
}


@media (max-width: 1023px){
	.imovel-ver-titulo {

		& .valor {
			text-align: left;
		}
	}
}