

.box-editor, .cke_editable {
	display: block;
	width: 100%;
    height: auto;
	white-space: normal;
    word-wrap: break-word;
    background-color: rgba(0,0,0,0) !important;
	text-align: justify;
	text-justify: inter-word;

	& p, table, span{
		font-family: 'Muli', sans-serif !important;
		font-size: 13px !important;
		color: $gray1 !important;
		text-align: inherit !important;
		margin: 0px !important;
		padding: 0px !important;
		border: none !important;
		outline: 0px !important;
		list-style: none !important;
    	background-color: rgba(0,0,0,0) !important;
	}

	& b {
		font-size: 1.3rem;
		color: $gray2 !important;
		text-align: inherit !important;
    	background-color: rgba(0,0,0,0) !important;
	}

	& div{
		font-size: 1rem;
		display: block !important;
		width: 100% !important;
		font-family: 'Muli', sans-serif !important;
		margin: 0 !important;
		color: $gray1 !important;
		text-align: inherit !important;
    	background-color: rgba(0,0,0,0) !important;
	}

	& h1 {
		font-family: 'Muli', sans-serif !important;
		font-size: 17px !important;
		font-weight: 700 !important;
		line-height: 1.1em !important;
		padding: 0 !important;
		margin: 20px 0px 20px 0px !important;
		color: $gray1 !important;
    	background-color: rgba(0,0,0,0) !important;
	}

	& ul, ol {

		font-family: 'Muli', sans-serif !important;
		margin: 0 !important;
		margin-left: 20px !important;
		padding: 0 !important;
    	background-color: rgba(0,0,0,0) !important;

		& li{
			text-align: inherit !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
			font-family: 'Muli', sans-serif !important;
			list-style-type: disc !important;
			color: $gray1 !important;
			font-weight: 300 !important;
			font-size: 13px !important;
			line-height: 1.4em !important;
    		background-color: rgba(0,0,0,0) !important;
		}
	}
}
