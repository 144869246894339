.imovel-ver-preco {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	text-align: center;
}
.imovel-ver-disponivel {
	vertical-align: middle;

	padding: 1rem;
	margin: 1rem 0;
	@include border-box;
	background-color: $colorPrimary;
	font-size: 1.3rem;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
}

/*
	01 Sala, 01 Cozinha, 03 Garagens, etc..
 */

@media (max-width: 1023px){
	.imovel-ver-preco {
		margin-bottom:0 !important;
	}
	.imovel-ver-disponivel {
		margin-top:0 !important;
	}
}