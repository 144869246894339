.footer1{
	box-shadow: 0 0 15px 5px rgba($gray3, .15);
	width: 100%;
	display: block;

	& .logo_footer{
		display: block;
		width: 100%;

		& a{
			display: block;
			width: fit-content;

			img{
				max-width: 100px;
			}

		}
	}

	& .creci_footer{
		display: block;
		width: fit-content;
		margin: 0;

		& p{
			border: solid 1px lighten($gray4, 10);
			border-radius: 2rem;
			padding: .2rem 1.5rem;
			font-size: .9rem;
			font-weight: 600;

		}
	}

	& .rs_footer{
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;

		& a{
			margin: 0 .3rem;
			display: inline-block;
			vertical-align: middle;
			border-right: none;
			padding: 0;
			
			& p{
				border: solid 1px transparent;
				border-radius: .1rem;
				transition: all .2s ease-in-out;
				margin: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				
				& i{
					padding: .7rem;
					color: $colorTerciary;
					font-size: 1.7rem;
					transition: all .2s ease-in-out;
					margin: 0;
				}
			}

			&:hover{
				& p{
					& i{
						color: $colorPrimary;
					}
				}

				border-right: none;
				padding-right: none;
			}
		}
	}

	& .contatofooter{
		display: block;
		width: 100%;
		
		& .tel_email_footer{
			display: inline-block;
			width: 100%;
			vertical-align: top;

			& h3{
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
			}
			
			& a{
				display: block;
				width: fit-content;
				& p{
					font-size: .8rem;
					font-weight: 400;
					color: $gray2;
					transition: all .2s;
					&:hover{
						color: $colorPrimary;
					}
				}
			}
		}
	}
	
	& .menu_footer{
		display: block;
		width: 100%;

		& a{
			display: block;
			width: fit-content;
			position: relative;

			& p{
				font-size: .9rem;
				font-weight: 500;
				color: $gray1;
				border-left: solid 2px transparent;
				transition: all .2s;
			}

			& .underline_x{
				&::after{
					position: absolute;
					content: "";
					width: 0;
					height: 2px;
					background-color: transparent;
					border-radius: 2rem;
					left: 0%;
					bottom: 0;
					transition: all 0.25s ease-in-out;
				}
			}
			&:hover{
				& .underline_x{
					&::after{
						background-color: $colorPrimary;
						width: 100%;
					}
				}
			}
		}
	}

	& .endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: $gray1;

			& i{
				color: $colorSecondary;
			}
		}

		& p{
			font-size: .8rem;
			font-weight: 400;
			color: $gray2;
		}

		& a{
			width: fit-content;
			display: block;
			margin: 0;
			padding: 0;
			& p{
				margin: 0;
				padding: 0;
				font-size: .8rem;
				font-weight: 400;
				color: $colorPrimary;
				transition: all .2s;
				border-left: solid 2px transparent;
				&:hover{
					border-left: solid 1px $colorPrimary;
					padding-left: .5rem;
				}
			}
		}
	}

	& .horarioatendimento_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: .9rem;
			font-weight: 600;
			color: $gray1;
			& i{
				color: $colorSecondary;
			}
		}

		& p{
			font-size: .8rem;
			font-weight: 400;
			color: $gray2;
		}

		& span{
			font-size: .8rem;
			font-weight: 400;
			color: $gray2;
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: $gray2 !important;
			font-size: .8rem;
			font-weight: 400;
			& strong{
				color: $gray2;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 60%;

			& p{
				text-align: right;
				color: $gray2;
				font-size: .8rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 15%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	
	.footer1{
		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& .developed{
				display: inline-block;
				width: 33%;
				vertical-align: middle;

				& p{
					text-align: right;
					color: $gray2;
					font-size: .8rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 33%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 8rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1{

		& .logo_footer{
			& a{
				margin: auto;
			}
		}

		& .creci_footer{
			margin: auto;
		}
		
		& .rs_footer{
			& a{
				& p{
					& i{
						font-size: 2rem;
					}
				}
			}
		}

		& .contatofooter{
			& .tel_email_footer{
				& a{
					& p{
						font-size: .9rem;
					}
				}
			}
		}

		& .copyright_footer{
			& p{
				text-align: center;
			}
		}

		& .devby{
			& .developed{
				width: 100%;

				& p{
					text-align: center;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 50%;

				& .logo{
					width: 6rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 9rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}