.title_condomi{
	display: block;
	width: 100%;

	& h1{
		font-size: 2rem;
		font-weight: 600;
		color: $colorPrimary;
		text-align: center;
	}
	
	& h2{
		font-size: 2rem;
		font-weight: 600;
		color: $colorPrimary;
		text-align: center;
		margin-bottom: .5rem;

		& span{
			font-size: 1.1rem;
			font-weight: 400;
			color: $colorTerciary;
			margin: 0;
		}
	}

	& .under_p{
		text-align: center;
		width: 5rem;
		height: 2px;
		margin: auto;
		background-color: darken($colorSecondary, 5);
		border-radius: 2rem;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: white;

	& .img_contato_index{
		display: block;
		width: 100%;

		& .img{
			padding: 1rem;
			width: 70%;
			display: block;
			margin: auto;
			position: relative;
			z-index: 1;
			
			& .fundomimg{
				position: absolute;
				top: 10%;
				left: -3%;
				background-color: darken($colorSecondary, 5);
				border-radius: 1rem;
				width: 90%;
				height: 92%;
				z-index: -1;
			}
			
			& img{
				width: 100%;
				z-index: 2;
				display: block;
				border-radius: 1rem;
			}
		}
	}

	& .content_contatos {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1rem;
		width: 100%;
		border-radius: 1rem;

		& a{
			display: inline-block;
			width: 100%;
			vertical-align: top;
			padding: 2rem;
			border-radius: .1rem;
			transition: all .25s ease-in-out;
			border-radius: 1rem;
	
			& .bloco_servico{
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: middle;
	
				& .img{
					width: calc(512px / 8);
					height: calc(512px / 8);
					display: inline-block;
					vertical-align: middle;
					margin: 0;
	
					& img{
						width: calc(512px / 8);
						height: calc(512px / 8);
						transition: all .15s;
						text-align: left;
					}
				}
	
				& .descricao{
					width: fit-content;
					margin-left: 0;
					display: block;
					position: relative;
					padding-left: 2rem;
	
					& h2{
						color: $colorPrimary;
						font-size: 1rem;
						font-weight: 600;
						text-align: left;
						margin: 0;
						transition: all .25s ease-in-out;
					}
	
					& p{
						padding: 0;
						color: $gray2;
						font-size: .9rem;
						font-weight: 400;
						text-align: left;	
						transition: all .25s ease-in-out;
					}
				}
			}
	
			&:hover{
				background-color: $colorPrimary;

				& .bloco_servico{
					& .descricao{
						& h2{
							color: $gray4;
						}
		
						& p{
							color: white;
						}
					}
				}
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		color: $gray1;
		font-size: 1.8rem;
		font-weight: 400;
	}


	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 1rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: -90%;
	z-index: 999999;
}

.botao_voltar{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: 2rem;
	padding: .5rem 1.5rem;
	color: $colorPrimary !important;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: background-color .2s ease-in-out;

	& i {
		color: $colorSecondary;
		font-weight: 600;
	}

	&:hover{
		background-color: $gray5;
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorPrimary;
	vertical-align: middle;
	& i{
		color: $colorSecondary;
	}
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 5);
	border-radius: 1rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: 1rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorSecondary;
			top: 40%;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: lighten($gray5, 2);
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			& span{
				width: 11px !important;
				height: 11px !important;
				float: none !important;
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.section_servicos{
		& .img_contato_index{
			& .img{
				width: 80%;
			}
		}
	
		& .content_contatos {
			& a{
				padding: 1.5rem 2rem;
			}
		}
	}
}

@media all and (max-width: 1023px){

	.bg_busca_interna{
	
		& h2{
			text-align: center;
			color: $gray1;
			font-size: 1.5rem;
			font-weight: 400;
		}
	
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.title_condomi{
		& h2{
			font-size: 1.5rem;
		}
	}

	.section_servicos{
		& .img_contato_index{
			display: block;
			width: 100%;
			margin-bottom: 2rem;
	
			& .img{
				width: 100%;
			}
		}
	
		& .content_contatos {
			gap: 2rem;
			& a{
				padding: 1rem;
				& .bloco_servico{
					align-items: start;

					& .img{
						width: calc(512px / 9);
						height: calc(512px / 9);
		
						& img{
							width: calc(512px / 9);
							height: calc(512px / 9);
						}
					}
				}
			}
		}
	}

	#ux_to_top{
		left: 15%;
		top: -80px;
	}
}