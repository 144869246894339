.text_descricao_imo{
	display: block;
	width: 100%;

	& h2{
		color: $gray1;
		font-size: 1.1rem;
		font-weight: 600;
	}

	& p{
		font-size: .9rem;
	}

	& .informacoes_tens{
		color: $gray2;
		font-size: .9rem;
		font-weight: 400;

		& i{
			font-size: 1.1rem;
			color: $gray1;
		}
	}
}

.imo_title{
	display: block;
	width: 100%;

	& h1{
		font-size: 1.5rem;
		font-weight: 500;
		color: $colorPrimary;
	}

	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
		
		& i {
			color: $colorSecondary;
		}
	}
}

.container_troca_promocao{
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	margin-bottom: 3rem;

	& .box_troca_promocao{
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		border-radius: .5rem;
		padding: .5rem 1rem;
		margin: 0;
		
		& .title{
			font-size: 1rem;
			font-weight: 500;
			color: $colorPrimary;

			& i {
				color: $colorSecondary;
			}
		}
	
		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray2;
		}
	}
}

.info_valores_mob{
	width: 100%;
	display: block;

	& .acao_tipo{
		font-size: 1rem;
		color: $gray2;
		font-weight: 400;
		margin: 0;
	}

	& .tarja_valor{
		font-size: 1.5rem;
		margin: 0;
		color: $colorPrimary;
		font-weight: 600;
		padding-bottom: .5rem;
		border-bottom: solid 1px lighten($gray4, 10);
	}

	& .valor_cond{
		width: 100%;
		display: block;
		box-sizing: border-box;
		margin-bottom: 1rem;

		& .titlecond{
			font-size: 1rem;
			font-weight: 500;
			color: $gray2;
			margin: 0;
			vertical-align: middle;

			& i{
				font-size: 1.1rem;
				font-weight: 600;
				vertical-align: middle;
				color: $colorSecondary;
				cursor: pointer;
			}
		}
		
		& p{
			font-size: .9rem;
			font-weight: 400;
			color: lighten($gray2, 5);
			margin: .2rem 0;
		}
	}
}

.formulario_interno{
	background-color: white;
	padding: 1rem;
	border-radius: 1rem;
	box-shadow: 0 0 10px 0 rgba($gray4, .5);

	& h4{
		font-size: 1.5rem;
		font-weight: 600;
		color: $gray1;
		text-align: center;
		margin: 0;
	}

	& #form_pag_interna{
   	 	padding: 1rem 0 0 0;
	}

	& .btn_zap_interno{
		width: fit-content;
		display: block;
		padding: 0 2rem;
		background-color: #00af53;
		margin: auto;
		border-radius: 2rem;
		cursor: pointer;
		transition: background-color .2s ease-in-out;

		& p{
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			padding: .5rem 0;
			vertical-align: middle;
			user-select: none;

			& i{
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}

		&:hover{
			background-color: darken(#00af53, 5);
		}
	}

	& .section_dialog2{
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		box-shadow: 0 0 10px 1px rgba(black, .1);
		padding: 1rem;
		border-radius: 1rem;
		background-color: white;

		& .btn_fechar_form_zap{
			display: flex;
			position: absolute;
			top: -1.8rem;
			right: -1.8rem;
			background-color: white;
			border: solid 2px #00af53;
			padding: .5rem;
			border-radius: 50%;
			width: 2.1rem;
			height: 2.1rem;
			align-items: center;
			justify-content: center;
			transition: all .2s ease-in-out;
			cursor: pointer;
			& i{
				margin: 0;
				padding: 0;
				line-height: initial;
				color: $gray3;
				transition: all .2s ease-in-out;
			}

			&:hover{
				background-color: #00af53;
				& i{
					color: white;
				}
			}
		}

		& .dialog_wpp{
			display: block;
			width: 100%;

			& h2{
				font-size: .9rem;
				color: $gray1;
				text-align: center;
			}

			& .inputs_wpp{
				display: block;
				width: 100%;

				& .formulario-titulo{
					font-size: .9rem;
					color: $gray2;
					font-weight: 400;
					margin: 0;
					line-height: 1.2rem;
					padding-left: .5rem;
				}

				& .inputs_dados{
					display: block;
					width: 100%;

					& input{
						border: solid 1px lighten($gray4, 10);
						border-radius: 2rem;
						padding: .5rem 1rem;
						width: 100%;
					}
				}

				& .input_msg{
					display: block;
					width: 100%;

					& input{
						border: solid 1px lighten($gray4, 10);
						border-radius: 2rem;
						padding: .5rem 1rem;
						width: 100%;
					}

					& button{
						width: 100%;
						display: block;
						background-color: #00af53;
						color: white;
						font-size: .9rem;
						font-weight: 600;
						padding: .5rem 0;
						border: none;
						border-radius: 2rem;
						cursor: pointer;
						transition: all .2s ease-in-out;

						& i{
							font-weight: 400;
						}

						&:hover{
							background-color: darken(#00af53, 5);
						}
					}
				}
			}
		}
	}

	& .info_valores{
		width: 100%;
		display: block;

		& .acao_tipo{
			font-size: .9rem;
			color: $gray2;
			font-weight: 400;
			margin: 0;
		}
	
		& .tarja_valor{
			font-size: 2rem;
			color: $colorPrimary;
			margin: 0;
			font-weight: 600;
			padding-bottom: .5rem;
			border-bottom: solid 1px lighten($gray4, 10);
		}

		& .valor_cond{
			width: 100%;
			display: block;
			box-sizing: border-box;
			margin-bottom: 1rem;

			& .titlecond{
				font-size: .9rem;
				font-weight: 500;
				color: $gray2;
				margin: 0;
				vertical-align: middle;

				& i{
					font-size: 1.1rem;
					font-weight: 600;
					vertical-align: middle;
					color: $colorSecondary;
					cursor: pointer;
				}
			}
			
			& p{
				font-size: .8rem;
				font-weight: 400;
				color: lighten($gray2, 5);
				margin: .2rem 0;
			}
		}
	}

	& .enviar_fechar_form_interna{
		display: flex;
		width: 100%;
		justify-content: flex-end;

		& .btn_enviar_email{
			width: 100%;
			padding: .5rem 0;
			background-color: $colorSecondary;
			color: $colorPrimary;
			border: none;
			border-radius: 2rem;
			font-weight: 600;
			font-size: .9rem;
			text-transform: uppercase;
			letter-spacing: .03rem;
			transition: all .2s ease-in-out;
			cursor: pointer;

			&:hover{
				background-color: lighten($colorSecondary, 5);
			}
		}
	}
}

.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;

	& p{
		color: $gray2;
		font-size: .9rem;
		font-weight: 400;
		line-height: 1.5rem;
	}
}

.container_utils_imovel{
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;

	& .item_utils_imovel{
		background-color: white;
		border-radius: 2rem;
		border: solid 1px lighten($gray4, 10);
		padding: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: .5rem;
		width: 100%;
	
		& p{
			color: $gray1;
			font-size: .9rem;
			font-weight: 400;
			border-radius: inherit;
			border: none;
		}
	
		& i{
			display: inline-block;
			vertical-align: middle;
			font-size: 1.4rem !important;
			margin: 0 !important;
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.imo_title{
		display: block;
		width: 100%;
	
		& h1{
			font-size: 1.1rem;
		}
	
		& p{
			font-size: .9rem;
		}
	}
}

@media all and (max-width: 1023px){

	.imo_title{
		& h1{
			font-size: 1rem;
		}
		& p{
			font-size: .9rem;
		}
	}

	.formulario_interno{
		& h4{
			font-size: 1.5rem;
			font-weight: 600;
			color: $colorPrimary;
		}
	}

	.texto_descricao_imo{
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}

	.width_pc_mobile{
		width: 100% !important;
	}

	.container_utils_imovel{
		width: 100%;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1rem;

		& .item_utils_imovel{
			& p{
				font-size: 1rem;
			}
		}

		& .btn_entrar_em_contato_mobile{
			display: block;
			width: 100%;
			
			& button{
				width: 100%;
				padding: .8rem 0;
				border: solid 1px $colorPrimary;
				background-color: $colorPrimary;
				color: white;
				font-size: 1rem;
				font-weight: 600;
				border-radius: 2rem;
				& i{
					font-weight: 600;
				}
				&:active{
					background-color: darken($colorPrimary, 5);
				}
			}
		}
	}
}