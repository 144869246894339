.mapa {
	display: block;
	width: 100%;
	position: relative;
	height: 60vh;

	& #map {
		height: 60vh;
		border-radius: $borderRadius;

	}
	& .mapapadrao{
		display: block;
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		background-image: url('/jnh/tp01/map/padrao.jpg');
		cursor: pointer;
		user-select: none;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		border-radius: $borderRadius;
	}
}

.imovel-financiamento{
	display: block;
	width: 130px;

	& img {
		display: block;
		width: 100%;
	}
}

/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin map--smart-portrait(){
	.mapapadrao{
		background-size: auto;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin map--smart-old(){
	.mapapadrao{
		background-size: auto;
	}
}