.text_404{
	width: 50%;
	& h2{
		color: $gray2;
		text-align: left;
		font-size: 1.3rem;
		padding-bottom: .4rem;
		font-weight: 600;

	}
	& h1{
		color: $gray2;
		text-align: left;
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 600;
	}

	& a{
		background-color: $colorPrimary;
		color: white;
		padding: .5rem 1rem;
		border-radius: 1rem;
		box-shadow: 5px 7px 15px rgba(black, .4);
		&:hover{
			background-color: lighten($colorPrimary, 10);
			box-shadow: 5px 7px 15px rgba($colorPrimary, .4);
			transition: all .30s;
		}
	}
}

@media (max-width: 1023px){
	.text_404{
		width: 100%;
		& h2{
			color: $gray2;
			text-align: left;
			font-size: 1.3rem;
			padding-bottom: .4rem;
			font-weight: 600;
	
		}
		& h1{
			color: $gray2;
			text-align: left;
			font-size: 1.5rem;
			line-height: 2rem;
			font-weight: 600;
		}
	
		
	}
	.img_404{
		& img{
			width: 100%;
		}
		& a{
			text-align: center;
			background-color: $colorPrimary;
			color: white;
			padding: .5rem 1rem;
			border-radius: 1rem;
			box-shadow: 5px 7px 15px rgba(black, .4);
			margin-left: 20%;
		}
	}
}